import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="about-pg">
      <h3>Privacy Policy</h3>
      <div className="about-rwa">
        <div className="about-about-rwa">
          <b>1. Introduction </b>
          <p>
            RWA.GUIDE ("we," "us," or "our") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you visit our website
            www.rwatracker.com and use our services. By using our website and
            services, you agree to the terms of this Privacy Policy.
          </p>
        </div>
        <div className="rwacamp-about">
          <b>2. 2. Information We Collect</b>
          <p>
            2.1 Personal Information: We may collect personal information that
            you provide directly to us when you create an account, subscribe to
            our newsletter, or contact us for support. This information may
            include your name, email address, and other contact details.
            <br />
            2.2 Usage Information: We automatically collect certain information
            about your device and how you interact with our website. This
            information may include your IP address, browser type, operating
            system, referring URLs, pages viewed, and the dates/times of your
            visits.
            <br />
            2.3 Cookies and Tracking Technologies: We use cookies and similar
            tracking technologies to track the activity on our website and store
            certain information. You can instruct your browser to refuse all
            cookies or to indicate when a cookie is being sent. However, if you
            do not accept cookies, you may not be able to use some portions of
            our website.
          </p>
          <b>3. How We Use Your Information</b>

          <p>
            We use the information we collect for various purposes, including:
            <br />
            To provide, operate, and maintain our website and services. <br />
            To improve, personalize, and expand our website and services. <br />
            To understand and analyze how you use our website and services.{" "}
            <br />
            To develop new products, services, features, and functionality.{" "}
            <br />
            To communicate with you, including customer service, support, and
            updates. <br />
            To send you promotional information, newsletters, and marketing
            materials. <br />
            To detect, prevent, and address technical issues or fraudulent
            activities. <br />
          </p>
          <b>4. Disclosure of Your Information</b>
          <p>
            We may share your information in the following circumstances:
            <br />
            4.1 Service Providers: We may employ third-party companies and
            individuals to facilitate our services ("Service Providers"),
            provide services on our behalf, perform service-related tasks, or
            assist us in analyzing how our website and services are used. These
            third parties have access to your personal information only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
            <br />
            4.2 Legal Requirements: We may disclose your information if required
            to do so by law or in response to valid requests by public
            authorities (e.g., a court or a government agency).
            <br />
            4.3 Business Transfers: If we are involved in a merger, acquisition,
            or asset sale, your personal information may be transferred. We will
            provide notice before your personal information is transferred and
            becomes subject to a different privacy policy.
            <br />
            4.4 Protection of Rights: We may disclose your information if we
            believe in good faith that such action is necessary to (a) comply
            with legal obligations, (b) protect and defend our rights or
            property, (c) prevent or investigate possible wrongdoing in
            connection with our services, or (d) protect the personal safety of
            users of our services or the public.
          </p>
          <b>5. Data Security</b>

          <p>
            We use commercially reasonable security measures to protect your
            information. However, no method of transmission over the Internet or
            method of electronic storage is 100% secure, and we cannot guarantee
            absolute security.
            <br />
          </p>
          <b> 6. Your Data Protection Rights</b>

          <p>
            Depending on your location, you may have the following rights
            regarding your personal information:
            <br />
            The right to access – You have the right to request copies of your
            personal information. <br />
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate or incomplete.
            <br />
            The right to erasure – You have the right to request that we erase
            your personal information, under certain conditions.
            <br />
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal information, under
            certain conditions.
            <br />
            The right to object to processing – You have the right to object to
            our processing of your personal information, under certain
            conditions.
            <br />
            The right to data portability – You have the right to request that
            we transfer the information that we have collected to another
            organization, or directly to you, under certain conditions.
            <br />
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us at
            privacy@rwatracker.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
