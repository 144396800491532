import AdminNavbar from "./AdminNavbar";

const AdminUser = () => {
  return (
    <div>
      <AdminNavbar />
      <div className="container">AdminUser</div>
    </div>
  );
};

export default AdminUser;
