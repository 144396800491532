import axios from "axios";

const baseUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === "development") {
    return process.env.REACT_APP_API_DEV_URL;
  } else if (process.env.REACT_APP_NODE_ENV === "production") {
    return process.env.REACT_APP_API_PROD_URL;
  }
};

const API = axios.create({
  baseURL: baseUrl(),
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const APIFile = axios.create({
  baseURL: baseUrl(),
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});

// It helps to send Token through headers
API.interceptors.request.use((req) => {
  if (localStorage?.getItem("token")) {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  }
  return req;
});

// It helps to send Token through headers
APIFile.interceptors.request.use((req) => {
  if (localStorage?.getItem("token")) {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  }
  return req;
});

// currencies or token apis
export const GetRWACategories = () =>
  API.get(`api/currencies/rwa/categories?page=${2}&size=${10}`);
export const GetRWACurrencies = (data) =>
  API.get(
    `/api/currencies?page=${data.page}&size=${data.size}&category=${data.category}&sortBy=${data.sortBy}&sortDirection=${data.sortDirection}`
  );
export const TokenDetails = (data) =>
  API.get(`api/currencies/rwa/coin/${data}`);
export const HighlightsRWAData = () => API.get(`/api/currencies/rwa/highlight`);
export const TrendingData = () => API.get("/api/currencies/rwa/trend");
export const CoinGraphData = (data) =>
  API.get(`/api/currencies/rwa/graph/coinOHLC/${data}`);
export const GetBlog = () => API.get("/api/currencies/rwa/blog");
export const GetNews = () => API.get("/api/currencies/rwa/news");
export const GetNewsDetails = (data) =>
  API.get(`/api/currencies/rwa/news/${data}`);

// user apis
export const GetVerifyEmail = (data) => API.get(`/api/users/verify/${data}`);
export const UserSignUp = (data) => API.post("/api/users/signup", data);
export const UserLogin = (data) => API.post("/api/users/signin", data);
export const ResetPassword = (data) =>
  API.post(`/api/users/reset-password/${data.token}`, data.pass);
export const ForgotPassword = (data) =>
  API.post("/api/users/forgot-password", data);

// user token apis
export const FavCoin = (data) => API.get(`/api/users/fav/coin/${data}`);
export const AddToken = (data) => APIFile.post("/api/user/token/add/new", data);

export const AddTokenPortfolio = (data) =>
  API.get(`api/user/token/add/portfolio/${data}`);

export const GetUserToken = () => API.get(`/api/user/token/portfolio`);
export const PostUserTransaction = (data) =>
  API.post(`/api/user/token/portfolio/${data.id}`, data);
