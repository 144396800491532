import React from "react";
import AdminNavbar from "./AdminNavbar";
import { useForm } from "react-hook-form";
import "./Admin.css";
const categories = [
  "Real Estate",
  "Precious Metals",
  "Agriculture",
  "Gold",
  "Art",
  "Silver",
  "Rare Coins",
  "Luxury Assets",
];
const AdminBlogs = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // console.log(data);
  };

  const sections = watch("sections", [
    { title: "", content: [{ subtitle: "", details: "" }] },
  ]);

  return (
    <div>
      <AdminNavbar />
      <div className="container">
        <div className="form-admin-blogs">
          <form onSubmit={handleSubmit(onSubmit)} className="form-admin-blog">
            {" "}
            <h3>Blogs Form</h3>
            <div>
              <label className="me-2">Image Path</label>
              <input
                type="file"
                {...register("image", { required: "Image path is required" })}
              />
              {errors.image && <p className="error">{errors.image.message}</p>}
            </div>
            <div>
              <label className="me-2">Category</label>
              <select
                {...register("category", { required: "Category is required" })}
              >
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              {errors.category && (
                <p className="error">{errors.category.message}</p>
              )}
            </div>
            <div>
              <label>Author</label>
              <input type="text" defaultValue="Admin" {...register("by")} />
            </div>
            <div>
              <label className="me-2">Date</label>
              <input
                type="date"
                {...register("date", { required: "Date is required" })}
              />
              {errors.date && <p className="error">{errors.date.message}</p>}
            </div>
            <div>
              <label>Blog Title</label>
              <input
                type="text"
                {...register("blogTitle", {
                  required: "Blog Title is required",
                })}
              />
              {errors.blogTitle && (
                <p className="error">{errors.blogTitle.message}</p>
              )}
            </div>
            <div>
              <label>Introduction</label>
              <textarea
                {...register("intro", { required: "Introduction is required" })}
              />
              {errors.intro && <p className="error">{errors.intro.message}</p>}
            </div>
            <div>
              <label>Block Quote Text</label>
              <textarea
                {...register("blockQuoteText", {
                  required: "Block Quote Text is required",
                })}
              />
              {errors.blockQuoteText && (
                <p className="error">{errors.blockQuoteText.message}</p>
              )}
            </div>
            <div>
              <label>Block Quote Cite</label>
              <input
                type="text"
                defaultValue="Admin"
                {...register("blockQuoteCite")}
              />
            </div>
            <div>
              <label className="mb-3">Sections</label>
              {sections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <div>
                    <label>Section Title</label>
                    <input
                      type="text"
                      {...register(`sections[${sectionIndex}].title`, {
                        required: "Section Title is required",
                      })}
                    />
                    {errors.sections?.[sectionIndex]?.title && (
                      <p className="error">
                        {errors.sections[sectionIndex].title.message}
                      </p>
                    )}
                  </div>
                  {section.content.map((content, contentIndex) => (
                    <div key={contentIndex}>
                      <div>
                        <label>Subtitle</label>
                        <input
                          type="text"
                          {...register(
                            `sections[${sectionIndex}].content[${contentIndex}].subtitle`,
                            { required: "Subtitle is required" }
                          )}
                        />
                        {errors.sections?.[sectionIndex]?.content?.[
                          contentIndex
                        ]?.subtitle && (
                          <p className="error">
                            {
                              errors.sections[sectionIndex].content[
                                contentIndex
                              ].subtitle.message
                            }
                          </p>
                        )}
                      </div>
                      <div>
                        <label>Details</label>
                        <textarea
                          {...register(
                            `sections[${sectionIndex}].content[${contentIndex}].details`,
                            { required: "Details are required" }
                          )}
                        />
                        {errors.sections?.[sectionIndex]?.content?.[
                          contentIndex
                        ]?.details && (
                          <p className="error">
                            {
                              errors.sections[sectionIndex].content[
                                contentIndex
                              ].details.message
                            }
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div>
              <label>Conclusion</label>
              <textarea
                {...register("conclusion", {
                  required: "Conclusion is required",
                })}
              />
              {errors.conclusion && (
                <p className="error">{errors.conclusion.message}</p>
              )}
            </div>
            <div className="submit-btn">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminBlogs;
