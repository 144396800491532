import React from "react";
import { Table, Tooltip } from "react-bootstrap";
import SevenDays from "../Common/Graph/SevenDays";
import AdminNavbar from "./AdminNavbar";
const staticData = [
  {
    id: 1,
    name: "Token A",
    price: 150.5,
    oneHourChange: 2.3,
    twentyFourHourChange: 5.7,
    sevenDayChange: 12.8,
    marketCap: 1.5e9,
    totalVolume: 1.2e7,
    circulatingSupply: 1e6,
  },
  {
    id: 2,
    name: "Token B",
    price: 200.8,
    oneHourChange: -0.5,
    twentyFourHourChange: 3.2,
    sevenDayChange: 8.1,
    marketCap: 2.3e9,
    totalVolume: 2.3e7,
    circulatingSupply: 2e6,
  },
  {
    id: 3,
    name: "Token C",
    price: 320.2,
    oneHourChange: 1.8,
    twentyFourHourChange: 4.5,
    sevenDayChange: 10.3,
    marketCap: 3.8e9,
    totalVolume: 3.1e7,
    circulatingSupply: 3e6,
  },
];
const AdminCoin = () => {
  return (
    <div>
      <AdminNavbar />
      <div className="container">
        <div className="table-market-cap">
          <div className="my-2">
            <div className="tableBodyForMarketCap">
              <div
                className="table-container"
                style={{ overflowX: "auto", scrollbarWidth: "thin" }}
              >
                <Table className="MarketCapTable">
                  <thead className="">
                    <tr>
                      <th className="text-end serial-no"></th>
                      <th className="text-end serial-no">#</th>
                      <th className="text-start category">Name</th>
                      <th className="text-end">
                        <div className="tr-eq-width">
                          <div className="tr-th-header">Price</div>
                          <div className="tooltip-icon">
                            <Tooltip text="Average change in market price over the last 24 hours across all cryptocurrencies within a category." />
                          </div>
                        </div>
                      </th>
                      <th className="text-end">
                        <div className="tr-eq-width">
                          <div className="tr-th-header">1h %</div>
                          <div className="tooltip-icon">
                            <Tooltip text="Cryptocurrencies that have increased the most in price over the last 1 hour." />
                          </div>
                        </div>
                      </th>
                      <th className="text-end">
                        <div className="tr-eq-width">
                          <div className="tr-th-header">24h %</div>
                          <div className="tooltip-icon">
                            <Tooltip text="Cryptocurrencies that have increased the most in price over the last 24 hours." />
                          </div>
                        </div>
                      </th>
                      <th className="text-end">
                        <div className="tr-eq-width">
                          <div className="tr-th-header">7d %</div>
                          <div className="tooltip-icon">
                            <Tooltip text="Total value of constituent traded (bought or sold) in the last 7 days" />
                          </div>
                        </div>
                      </th>
                      <th className="text-end">
                        <div className="tr-eq-width">
                          <div className="tr-th-header">Market Cap</div>
                          <div className="tooltip-icon">
                            <Tooltip text="Total number of market cap" />
                          </div>
                        </div>
                      </th>
                      <th className="text-end">
                        <div className="tr-eq-width">
                          <div className="tr-th-header">Total Volume</div>
                          <div className="tooltip-icon">
                            <Tooltip text="Total volume of the token" />
                          </div>
                        </div>
                      </th>
                      <th className="text-end">
                        <div className="tr-eq-width">
                          <div className="tr-th-header">Circulating Supply</div>
                          <div className="tooltip-icon">
                            <Tooltip text="Circulating supply of the token" />
                          </div>
                        </div>
                      </th>
                      <th className="text-center">
                        <div className="tr-graph">
                          <div className="tr-th-header">Last 7 Days</div>
                          <div className="tooltip-icon">
                            <Tooltip text="Visual representation of the average market cap trend over the last 7 days." />
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {staticData.map((data, i) => (
                      <tr key={i} style={{ cursor: "pointer" }}>
                        <td className="text-end">
                          <button
                            style={{
                              border: "none ",
                              background: "transparent",
                            }}
                          ></button>
                        </td>
                        <td className="text-end">{data.id}</td>
                        <td className="text-start category category-img">
                          {data.name}
                        </td>
                        <td className="text-end fw-bold">
                          ${data.price.toFixed(2)}
                        </td>
                        <td
                          className={`text-end fw-bold ${
                            data.oneHourChange > 0
                              ? "price-fluctuations-up"
                              : "price-fluctuations-down"
                          }`}
                        >
                          {data.oneHourChange > 0 ? (
                            <i className="fa fa-caret-up me-1"></i>
                          ) : (
                            <i className="fa fa-caret-down me-1"></i>
                          )}
                          {Math.abs(data.oneHourChange.toFixed(2))}%
                        </td>
                        <td
                          className={`text-end fw-bold ${
                            data.twentyFourHourChange > 0
                              ? "price-fluctuations-up"
                              : "price-fluctuations-down"
                          }`}
                        >
                          {data.twentyFourHourChange > 0 ? (
                            <i className="fa fa-caret-up me-1"></i>
                          ) : (
                            <i className="fa fa-caret-down me-1"></i>
                          )}
                          {Math.abs(data.twentyFourHourChange.toFixed(2))}%
                        </td>
                        <td
                          className={`text-end fw-bold ${
                            data.sevenDayChange > 0
                              ? "price-fluctuations-up"
                              : "price-fluctuations-down"
                          }`}
                        >
                          {data.sevenDayChange > 0 ? (
                            <i className="fa fa-caret-up me-1"></i>
                          ) : (
                            <i className="fa fa-caret-down me-1"></i>
                          )}
                          {Math.abs(data.sevenDayChange.toFixed(2))}%
                        </td>
                        <td className="text-end">
                          ${data.marketCap.toFixed(2)}
                        </td>
                        <td className="text-end">
                          ${data.totalVolume.toFixed(2)}
                        </td>
                        <td className="text-end">
                          {data.circulatingSupply.toFixed(2)}
                        </td>
                        <td className="text-end tracked-map">
                          <SevenDays />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCoin;
