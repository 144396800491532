import React from "react";
import { Table, Tooltip } from "react-bootstrap";
import SevenDays from "../Common/Graph/SevenDays";
import AdminNavbar from "./AdminNavbar";
import "./Admin.css";

const Admin = () => {
  return (
    <div>
      <AdminNavbar />
      <div className="container">Dashboard</div>
    </div>
  );
};

export default Admin;
