import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import Searchbar from "../SearchBar/Searchbar";
import { AuthContext } from "../../App";
// import Logo from "../../Assets/img/logo.png";
import Logo from "../../Assets/img/rwa-logo-footer.png";
import "./Navbar.css";

const Navbar = () => {
  const { setAuthLogin } = useContext(AuthContext);
  const [userName, setUserName] = useState(
    localStorage.getItem("name") ?? null
  );

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    setUserName(null);
    setAuthLogin(false);
  };

  return (
    <div className="navbar-bg inter-font-500">
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <li className="nav-item">
                {/* <a className="nav-link" aria-current="page" href="/">
                  Cryptocurrencies
                </a> */}
                <a className="nav-link " aria-current="page" href="/">
                  Home
                </a>
              </li>{" "}
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/about">
                  About
                </a>
              </li>
              <li className="nav-item">
                {userName ? (
                  <NavLink
                    className="nav-link"
                    to="/dashboard"
                    aria-current="page"
                  >
                    Dashboard
                  </NavLink>
                ) : (
                  <NavLink className="nav-link" to="/login">
                    Dashboard
                  </NavLink>
                )}
              </li>{" "}
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/airdrop">
                  Airdrop
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/">
                  Research and Analysis
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/blogs">
                  Blogs
                </a>
              </li>{" "}
              {/* <li className="nav-item">
                <a className="nav-link" href="/">
                  Exchanges <span>(COMING SOON)</span>
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a className="nav-link" href="/">
                  Learn RWA <span>(COMING SOON)</span>
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a className="nav-link nav-link-products" href="/">
                  Products
                </a>
                <div className="nav-link-products-div">
                  <div className="nav-link-product-main">
                    <div className="nav-link-products-div-left">
                      <h6>Our Mission</h6>
                      <div className="nav-link-products-div-left-p">
                        <h4>Creating a hub for RWA projects globally.</h4>
                        <p>
                          To support the growing ecosystem of projects making
                          every possible asset available to every possible
                          person, anytime, anywhere.
                        </p>
                      </div>
                    </div>
                    <div className="nav-link-products-div-center">
                      <h6>Deep Dive</h6>
                      <div className="nav-link-products-div-box">
                        <NavLink to="/home">
                          <div className="products-nav-heading">
                            <div className="products-nav-heading">Insights</div>
                            <div className="products-nav-share">
                              <i className="fa fa-share-alt-square"></i>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Products
                </a>
                <ul className="dropdown-menu">
                  <div className="nav-link-products-div">
                    <div className="nav-link-product-main">
                      <div className="nav-link-products-div-left">
                        <h6>Our Mission</h6>
                        <div className="nav-link-products-div-left-p">
                          <div className="nav-link-product-div-top">
                            <h4>Creating a hub for RWA projects globally.</h4>
                            <p>
                              To support the growing ecosystem of projects
                              making every possible asset available to every
                              possible person, anytime, anywhere.
                            </p>
                            <br />
                          </div>
                          <div className="nav-link-product-div-bottom">
                            <button>Join us </button>
                          </div>
                        </div>
                      </div>
                      <div className="nav-link-products-div-center">
                        <h6>Deep Dive</h6>
                        <div className="nav-link-products-div-box">
                          <NavLink to="/home">
                            <div className="products-div-box">
                              <div className="products-nav-heading">
                                <div className="products-nav-sub-heading">
                                  Tracker
                                </div>
                                <div className="products-nav-share">
                                  <i className="fa fa-share-alt-square "></i>
                                </div>
                              </div>
                              <div className="products-nav-p">
                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Est quidem itaque provident,
                                  corrupti qui a. Est ratione eius eum ipsum!
                                </p>
                              </div>
                            </div>
                          </NavLink>{" "}
                          <NavLink
                            to="https://www.rwacamp.com/index.php"
                            target="_blank"
                          >
                            <div className="products-div-box">
                              <div className="products-nav-heading">
                                <div className="products-nav-sub-heading">
                                  Forum
                                </div>
                                <div className="products-nav-share">
                                  <i className="fa fa-share-alt-square"></i>
                                </div>
                              </div>{" "}
                              <div className="products-nav-p">
                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Est quidem itaque provident,
                                  corrupti qui a. Est ratione eius eum ipsum!
                                </p>
                              </div>
                            </div>
                          </NavLink>{" "}
                          <NavLink to="/home">
                            <div className="products-div-box">
                              <div className="products-nav-heading">
                                <div className="products-nav-sub-heading">
                                  Insights
                                </div>
                                <div className="products-nav-share">
                                  <i className="fa fa-share-alt-square"></i>
                                </div>
                              </div>{" "}
                              <div className="products-nav-p">
                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Est quidem itaque provident,
                                  corrupti qui a. Est ratione eius eum ipsum!
                                </p>
                              </div>
                            </div>
                          </NavLink>{" "}
                          <NavLink to="/home">
                            <div className="products-div-box ">
                              <div className="products-nav-heading">
                                <div className="products-nav-sub-heading">
                                  Insights
                                </div>
                                <div className="products-nav-share">
                                  <i className="fa fa-share-alt-square"></i>
                                </div>
                              </div>{" "}
                              <div className="products-nav-p">
                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Est quidem itaque provident,
                                  corrupti qui a. Est ratione eius eum ipsum!
                                </p>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Explore
                </a>
                <ul className="dropdown-menu">
                  <div className="nav-link-products-div-di">
                    <div className="nav-link-product-main">
                      <div className="">
                        <div className="nav-link-products-div-box">
                          <NavLink to="/home">
                            <div className="products-div-box products-div-box-explore">
                              <div className="products-nav-heading">
                                <div className="products-nav-sub-heading">
                                  <i className="fa fa-book-atlas"></i> Research
                                </div>
                              </div>
                              <div className="products-nav-p">
                                <p>Lorem ipsum dolor sit amet consectetur</p>
                              </div>
                            </div>
                          </NavLink>
                          <NavLink
                            to="https://www.rwacamp.com/index.php"
                            target="_blank"
                          >
                            <div className="products-div-box products-div-box-explore">
                              <div className="products-nav-heading">
                                <div className="products-nav-sub-heading">
                                  Forum
                                </div>
                              </div>{" "}
                              <div className="products-nav-p">
                                <p>Lorem ipsum dolor sit amet consectetur</p>
                              </div>
                            </div>
                          </NavLink>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </li> */}
            </ul>
            <form className="d-flex search-input me-2" role="search">
              <Searchbar />
            </form>
            {/* <button className="btn btn-outline-success me-2" type="submit">
              Login
            </button>
            <button className="btn btn-outline-success" type="submit">
              Sign Up
            </button> */}
            <button className="btn login-user" type="button">
              {userName ? (
                <div className="dropdown">
                  <button
                    className="btn login-button-a"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <NavLink to="#">Hey, {userName}</NavLink>
                  </button>

                  <ul className="dropdown-menu navbar-dash">
                    <li>
                      <NavLink to="/dashboard">
                        {/* //className="dropdown-item" */}
                        Dashboard
                      </NavLink>
                    </li>
                    <li onClick={logoutHandler}>
                      <NavLink>Logout</NavLink>
                      {/* className="dropdown-item" */}
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="login-button-a ">
                  <NavLink to="/login">
                    {/* <i className="fa-solid fa-user fa-lg"> </i> */}
                    Login
                  </NavLink>
                </div>
              )}
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
